import blank from '@assets/images/blank.png';
import developing from '@assets/images/developing.png';
import gold from '@assets/images/gold.png';
import platinum from '@assets/images/platinum.png';
import silver from '@assets/images/silver.png';
import titanium from '@assets/images/titanium.png';

/**
 * Retrieves text classes by partnerType param.
 * @method
 * @param {Text} partnerType - Partner Type
 * @returns {Text} Returns Text color classes
 */
export const getPartnerColorClasses = partnerType => {
  switch (partnerType) {
    case 'Titanium':
      return 'text-titanium';
    case 'Platinum':
      return 'text-platinum';
    case 'Gold':
      return 'text-gold';
    case 'Silver':
      return 'text-silver';
    case 'Developing':
      return 'text-blank';
    case 'Blank':
      return 'text-blank';
    default:
      return;
  }
};

/**
 * Retrieves partner icon color by partnerType param.
 * @method
 * @param {Text} partnerType - Partner Type
 * @returns {Text} Returns Text color code
 */
export const getPartnerIconPng = partnerType => {
  switch (partnerType) {
    case 'Titanium':
      return titanium.src;
    case 'Platinum':
      return platinum.src;
    case 'Gold':
      return gold.src;
    case 'Silver':
      return silver.src;
    case 'Developing':
      return developing.src;
    case 'Blank':
      return blank.src;
    default:
      return blank.src;
  }
};

export const getPartnerIconColor = partnerType => {
  switch (partnerType) {
    case 'Titanium':
      return '#c7421e';
    case 'Platinum':
      return '#109e90';
    case 'Gold':
      return '#bf9b30';
    case 'Silver':
      return '#5128c9';
    case 'Developing':
      return '#212121';
    case 'Blank':
      return '#212121';
    default:
      return '';
  }
};

/**
 * Retrieves partner tooltip by partnerType param.
 * @method
 * @param {Text} partnerType - Partner Type
 * @returns {Text} Returns Partner tooltip
 */
export const getPartnerTooltip = partnerType => {
  switch (partnerType) {
    case 'Titanium':
      return 'Titanium tier dealers have worked hard to reach the highest ranking with Kohler. These dealers meet inventory, factory certification, and customer support metrics to achieve this tier. They sell, install, service, and provide warranty support on KOHLER generators.';
    case 'Platinum':
      return 'Platinum tier dealers have reached the second highest ranking with Kohler. These dealers meet inventory, factory certification, and customer support metrics to achieve this tier. They sell, install, service, and provide warranty support on KOHLER generators.';
    case 'Gold':
      return 'Gold tier dealers provide great customer care and meet inventory, factory certification, and customer support metrics to achieve this tier. They sell, install, service, and provide warranty support on KOHLER generators.';
    case 'Silver':
      return 'Silver dealers provide quality customer care and meet factory certification requirements to sell, install, service, and provide warranty support on KOHLER generators.';
    default:
      return '';
  }
};

export const getPartnerType = partnerType => {
  switch (partnerType) {
    case 'Silver':
      return 'Silver';
    case 'Gold':
      return 'Gold';
    case 'Platinum':
      return 'Platinum';
    case 'Titanium':
      return 'Titanium';
    case 'Developing':
      return 'Developing';
    case '':
      return 'Blank';
    case undefined:
      return 'Blank';
    default:
      return;
  }
};
