import React, { useState } from 'react';
// import { ComponentContentTypes } from '@/lib/constants';
import { parseExperiences } from '@lib/pageParsers';
import { Experience } from '@ninetailed/experience.js-next';
import get from 'lodash/get';
import Section from '@components/section';
import PropTypes from 'prop-types';

const ComponentRenderer = props => {
  const contentTypeId = props.sys.contentType.sys.id;

  // const Component = ContentTypeMap[contentTypeId];

  // if (!Component) {
  //   console.warn(`${contentTypeId} can not be handled`);
  //   return null;
  // }

  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <Section
      key={`${props?.sys?.id} + ${contentTypeId}`}
      id={props?.sys?.id}
      selectedItem={selectedItem}
      onClick={item => setSelectedItem(item)}
      sectionData={props?.fields}
      sectionType={contentTypeId}
      position={props?.position}
      sectionBackgroundColor={props?.fields?.sectionBackgroundColor}
    />
  );
};

export const BlockRenderer = ({ block, position }) => {
  if (Array.isArray(block)) {
    return (
      <div>
        {block.map((b, index) => {
          if (!b) {
            return null;
          }
          return <BlockRenderer key={`block-${b.sys.id}`} block={b} position={index} />;
        })}
      </div>
    );
  }

  if (!block) {
    return null;
  }

  const contentTypeId = get(block, 'sys.contentType.sys.id');
  const { id } = block.sys;
  const parsedExperiences = parseExperiences(block);
  return (
    <Experience
      key={`${contentTypeId}-${id}`}
      {...block}
      id={id}
      position={position}
      component={ComponentRenderer}
      experiences={parsedExperiences}
    />
  );
};

BlockRenderer.propTypes = {
  block: PropTypes.any,
  position: PropTypes.number
};