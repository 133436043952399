import { useEffect } from 'react';
import cvx from 'classnames';
import {
  Experience,
  useNinetailed,
  useProfile
} from '@ninetailed/experience.js-next';
import CarouselComponent from '@components/carouselWrapper';
import HeadingWithSubText from '@components/headingWithSubText';
import VideoPlayer from '@components/videoPlayer';
import { getBgColorClasses, getHeroSectionSize } from '@utils/common';

export const HeroSectionV2 = ({ sectionData }) => {
  const {
    backgroundColor = 'Transparent',
    backgroundImage,
    backgroundVideo,
    contentItems = [],
    heroHeight = []
  } = sectionData;
  console.log(sectionData, ':::::checkingdata');
  // const ninetailed = useNinetailed();
  // const { profile } = useProfile();
  // const audiences = getAudiences();
  const heroImage = backgroundImage?.fields?.asset[0]?.fields?.file?.url;
  const url = backgroundVideo?.fields?.asset[0]?.fields?.file?.url;
  const heroImagePosition = 'Background';

  const autoplay = backgroundVideo?.fields?.autoplay;
  const altText = backgroundImage?.fields?.altText;

  const isCarousel = sectionData?.contentItems?.some(
    x => x?.fields?.carouselItem?.length
  );
  const heroSectionSizes = {
    Small: 'h-auto lg:h-[280px]',
    Medium: 'h-auto lg:h-[520px]',
    Large: 'h-auto lg:h-[calc(100vh-35px)]'
  };
  const cHeight = heroSectionSizes[heroHeight?.[0]] ?? '';
  const heroSectionSize = isCarousel
    ? cHeight
    : getHeroSectionSize(heroHeight?.[0]);
  if (
    contentItems?.some(item => item.sys?.contentType?.sys?.id === 'carousel')
  ) {
    return (
      <section
        data-testid='heroSectionV2'
        className={cvx('relative h-auto ', getBgColorClasses(backgroundColor))}
      >
        {contentItems?.map(item => {
          const sectionType = item.sys?.contentType?.sys?.id;
          if (sectionType === 'carousel') {
            return (
              <CarouselComponent
                key={item.sys.id}
                height={heroHeight?.[0]}
                carouselItems={item.fields.carouselItem}
                classes={{
                  carousel: cvx(
                    'top-0 left-0 right-0 hero-v2-carousel',
                    cHeight
                  ),
                  height: cHeight
                }}
              />
            );
          }
        })}
      </section>
    );
  }
  return (
    <section
      data-testid='heroSectionV2'
      className={cvx(
        'relative',
        heroSectionSize,
        getBgColorClasses(backgroundColor)
      )}
    >
      {(url || heroImage) && (
        <VideoPlayer
          videoUrl={url}
          heroImage={heroImage}
          heroImagePosition={heroImagePosition}
          autoplay={autoplay}
          altText={altText}
          backgroundColor={backgroundColor}
          height={heroSectionSize}
        />
      )}
      {contentItems?.map(item => {
        const sectionType = item.sys?.contentType?.sys?.id;
        console.log(sectionType, '::::typechecking');
        if (sectionType === 'headingWithSubText') {
          // render page title
          return (
            <HeadingWithSubText
              key={item.sys.id}
              fields={item.fields}
              classes={{
                sectionClasses: cvx(
                  'absolute top-0 left-0 right-0 !py-0',
                  heroSectionSize
                )
              }}
            />
          );
        } else if (sectionType === 'carousel') {
          return (
            <CarouselComponent
              key={item.sys.id}
              height={heroHeight?.[0]}
              carouselItems={item.fields.carouselItem}
              classes={{
                carousel: cvx(
                  'absolute top-0 left-0 right-0 hero-v2-carousel',
                  cHeight
                ),
                height: cHeight
              }}
            />
          );
        }
      })}
    </section>
  );
};

export default HeroSectionV2;
